import Tools from '../tools/Tools';
class Forms{
	constructor(){
		this.fn = ['init','reset'];
		Tools.autoBind(this,this.fn);
		this.init();
	}
	init(){
		$("form .checkbox label").on("click",this.fakeCheckBox);
		$(".reset").on('click',this.reset);
		$("#form_contact").on('submit', this.submit);
	}
	fakeCheckBox(){
		if($('.checkbox input').is(':checked')){
			$(".fakeCheckBox").addClass("checked");
			$('.checkbox input').attr("checked", "checked");
		} else {
			$(".fakeCheckBox").removeClass("checked");
			$('.checkbox input').removeAttr("checked");
		} 
	}
	reset(e){
		e.preventDefault();
		$(e.target).closest('form')[0].reset();

	}

	submit(){
		let form = $("#form_contact");
		var data = form.serializeArray();
		data.push({name: 'suivi', value: $('.checkbox input').attr('checked') ? 1 : 0});
		$.post(form.attr('action'), data, function(data){
			if(data == "OK"){
				$(".reset").click();
				$("#form_ok").removeClass("hide");
				var succesmsg = $("#form_ok").text();
				var oldAlert = document.getElementById("alert");
					if (oldAlert){
						document.body.removeChild(oldAlert);
					}
				var newAlert = document.createElement("div");
				newAlert.setAttribute("role", "alert");
				newAlert.setAttribute("id", "alert");
				var msg = document.createTextNode(succesmsg);
				newAlert.appendChild(msg);
				document.body.appendChild(newAlert);
			} else {	
				$("#form_error p").html(data);
     			
				var oldAlert = document.getElementById("alert");
					if (oldAlert){
						document.body.removeChild(oldAlert);
					}
				var newAlert = document.createElement("div");
				newAlert.setAttribute("role", "alert");
				newAlert.setAttribute("id", "alert");
				var msg = document.createTextNode(data);
				newAlert.appendChild(msg);
				document.body.appendChild(newAlert);
			}
		});
		return false;
	}

}
export default Forms;