import Tools from '../tools/Tools';
import AccessibilityHelper from '../tools/AccessibilityHelper';

class Videos{
	constructor(){
		this.player = null;
		this.target = $("#moodshot");
		this.mouvemoveTimer = null;
		this.playBtn = null;
		this.text = null;
		this.cover = null;
		this.mask = null;
		this.isMoving = false;
		this.canMove = false;
		this.isPlaying = false;
		this.paused = false;
		this.fn = ['init', 'switcheroo','createIframe','checkStatus','loadAPI','playerReady','prepVideo','mousemove','playVideo','showMask','hideMask','showBtn','showText','showCover','hideBtn','hideText','hideCover'];
		Tools.autoBind(this,this.fn);
		this.init();
	}

	init(){
		if($('.play.api').length){
			this.loadAPI();
			window.onYouTubeIframeAPIReady = ()=>{
				this.createIframe();
			};
			this.cover = $(".title-banner h1 img");
			this.mask = $("#mask");
			this.playBtn = $(".play.api .play-btn");
			this.text = $(".title-banner-content div");
			this.playBtn.on('click',this.prepVideo);
			$(".title-banner")[0].addEventListener('mousemove',this.mousemove);
		}

		let plays = document.querySelectorAll('.play');

		for ( let i = 0; i < plays.length; i++ ) {
			if ( !plays[i].classList.contains('api') ) {

				plays[i].addEventListener('click',this.switcheroo.bind(this));
				$(".video .close").on('click',this.removeFrame.bind(this));
			}
		}

	}

	removeFrame(e){
		e.preventDefault();
		let t = $(e.target).is('a') ? $(e.target) : $(e.target).parent();
		t.attr('aria-hidden','true');

		let iframe = t.next();
		let frame = iframe.parent();

		frame.find('.cover').removeClass('darker');
		TweenMax.to(
			iframe,
			1,
			{
				autoAlpha:0,
				onComplete:()=>{
					iframe.remove();
				}
			}
		);

		TweenMax.to(
			frame.next(),
			1,
			{
				autoAlpha:1
			}
		);
	}

	switcheroo(e){
		e.preventDefault();
		const t = $(e.target);
		const videoId = t.closest('.video').attr('data-id');
		let elId = $(e.currentTarget).attr('data-label');

		if ( $('.video').length ) {
			this.holder = $(".popUpTranscript #"+elId).find('.frame');
		} else {
			this.holder = t.closest('.video').find('.frame');
		}

		$(".popUpTranscript")[0].setAttribute("data-current-transcript", elId);

		const randId = Math.ceil(Math.random() * 1000);
		const html = `<iframe width="100%" id="video-${randId}" src="https://www.youtube.com/embed/${videoId}?autoplay=1&cc_load_policy=1&rel=0&amp;showinfo=0" tabindex="0" frameborder="0" allowfullscreen></iframe>`;
		$(html).appendTo(this.holder);
		if($(e.currentTarget).hasClass("openTranscript")){
			$("body").css("overflow","hidden");
			TweenMax.to($(".popUpTranscript"), 0.2, {css:{autoAlpha:1}, ease:'Cubic.easeOut', onComplete:()=>{
				const transcriptBtn = $(`.popUpTranscript #${elId} .ctaShow`);
				if(transcriptBtn[0])
					AccessibilityHelper.lockTab($(".popUpTranscript"), $(".popUpTranscript .close"), transcriptBtn, true);
				else
					AccessibilityHelper.lockTab($(".popUpTranscript"), $(".popUpTranscript .close"), $(".popUpTranscript .close"), true);
			}});
			TweenMax.to($(".popUpTranscript #"+elId), 0.2, {css:{autoAlpha:1}, ease:'Cubic.easeOut'});
			
		}else{
			let rand = randId;
			TweenMax.to(
				t.closest('.cover'),
				1,
				{
					autoAlpha:0,
					onComplete:()=>{
						t.closest('.cover').remove();
						document.getElementById('video-' + rand).focus();
					}
				}
			);
		}
	}

	createIframe(){

		let idvideo = document.querySelector('.title-banner-content .play').getAttribute('data-id');
		this.player = new YT.Player(
			'moodshot',
			{
				playerVars:{
					rel:0,
					controls:0,
					disablekb:0,
					modestbranding:1,
				},
				height:window.innerHeight,
				width:window.innerWidth,
				autoplay:0,
				videoId: idvideo,
				events:{
					'onReady': this.playerReady,
					'onStateChange': this.checkStatus
				}
			}
		);
	}
	checkStatus(e, r = false){
		if(e.data === 0){ // End
			this.isPlaying = false;
			this.showCover();
			this.playBtn.removeClass('paused');
			this.playBtn.addClass('playing');
			this.showBtn();
			this.showText();
		}
		if (r) {
			return e.data;
		}
	}
	loadAPI(){
		// Don't reload the api if it's already loaded and trigger the event if it's already there
		if(!document.getElementById('youtubeapi')){
			var tag = document.createElement('script');
			tag.id = "youtubeapi";
			tag.src = "https://www.youtube.com/iframe_api";
			$(tag).appendTo(document.getElementsByTagName('body')[0]);
		}else{
			this.createIframe();
		}
	}
	playerReady(){
		$(".title-banner iframe").attr('aria-hidden','true').attr('tabindex','-1');
		this.playBtn.removeClass('loading');
		this.playBtn.addClass('playing');
		this.showBtn();
	}

	mousemove(){
		if(this.canMove && this.isPlaying){
			if(this.isMoving){
				window.clearTimeout(this.mouvemoveTimer);
				this.mouvemoveTimer = window.setTimeout(this.hideBtn,1000);
			}else{
				window.clearTimeout(this.mouvemoveTimer);
				this.isMoving = true;
				this.showBtn();
			}
		}else{
			window.clearTimeout(this.mouvemoveTimer);
			this.showBtn();
		}
	}

	showCover(){
		TweenMax.to(
			this.cover,
			0.75,
			{
				autoAlpha:1
			}
		);
	}

	hideCover(){
		TweenMax.to(
			this.cover,
			0.75,
			{
				autoAlpha:0
			}
		);
	}

	showBtn(){
		TweenMax.to(
			this.playBtn,
			0.7,
			{
				autoAlpha:1,
				onComplete:()=>{
					if(this.isPlaying){
						this.canMove = true;
						this.mousemove();
					}
				}
			}
		);
	}
	showText(){
		TweenMax.to(
			this.text,
			0.7,
			{
				autoAlpha:1
			}
		);
	}
	showMask(){
		TweenMax.to(
			this.mask,
			0.75,
			{
				autoAlpha:1
			}
		);
	}

	hideMask(){
		TweenMax.to(
			this.mask,
			0.75,
			{
				autoAlpha:0
			}
		);
	}
	hideBtn(){
		TweenMax.to(
			this.playBtn,
			0.7,
			{
				autoAlpha:0,
				onComplete:()=>{
					this.canMove = true;
				}
			}
		);
		this.isMoving = false;
	}
	hideText(){
		TweenMax.to(
			this.text,
			0.7,
			{
				autoAlpha:0
			}
		);
	}

	prepVideo(e){
		e.preventDefault();

		this.hideMask();
		this.hideBtn();
		this.hideText();
		this.playVideo();

		this.hideCover();
		
	}

	playVideo(){
		if(!this.playBtn.hasClass('loading')){
			if(this.isPlaying){
				this.player.pauseVideo();
				this.paused = true;
				this.isPlaying = false;
				this.playBtn.removeClass('paused');
				this.playBtn.addClass('playing');
				this.showMask();
				this.showBtn();
				this.showText();
			}else{
				this.isPlaying = true;
				if(this.paused){
					this.hideText();
					this.hideMask();
					this.paused = false;
					this.player.playVideo();
				}else{
					this.player.playVideo();
				}
				this.playBtn.removeClass('playing');
				this.playBtn.addClass('paused');
			}
		}
		$(".play.api .play-btn").blur();
	}
}

export default Videos;
