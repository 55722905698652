import Tools from './Tools';
class AccessibilityHelper{
	static putFocusInView(){
		// console.log($('a:not([tabindex="1"]), button, input, [tabindex]:not([tabindex="1"])'));
		$('a:not([tabindex="1"]), button, input, [tabindex]:not([tabindex="1"])').on('focus',(e)=>{
			var el = e.target;
			var fitting = Tools.isFullyVisible(el); 
			if(!fitting.visible){
				$(window).one('keyup',(k)=>{
					var key = k.keyCode || k.which;
					if(key === 9){
						var destination, scrollTop, offsetY;
						offsetY = 70;
						scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
						if(fitting.biggerThanScreen){
							destination = scrollTop += el.getBoundingClientRect().top;
						}else{
							destination = (scrollTop += el.getBoundingClientRect().top) - (window.innerHeight / 2) + (el.offsetHeight / 2);
						}
						$(window).scrollTop(destination - offsetY);
					}
				});
			}
		});
	}

	static lockTab(container, first, last, closeOnEsc = false){
		AccessibilityHelper.container = container[0];
		AccessibilityHelper.first = first[0];
		AccessibilityHelper.last = last[0];
		AccessibilityHelper.closeOnEsc = closeOnEsc;

		// Focus on lightbox 1st element
		container[0].querySelector(".lightbox__first-focus").focus();

        document.addEventListener("keyup", AccessibilityHelper.onKeyPress);
	}

	static unlockTab(container, first, last){
        document.removeEventListener("keyup", AccessibilityHelper.onKeyPress);

		AccessibilityHelper.container = null;
		AccessibilityHelper.first = null;
		AccessibilityHelper.last = null;
		AccessibilityHelper.closeOnEsc = null;
	}

	static releaseTab(container, first, last){

	}

	static onKeyPress(event) {
		if(event.keyCode == 9) { // Tab pressed
			// Focus 1st element on lightbox if focused element not in lightbox
			if(!AccessibilityHelper.container.contains(document.activeElement)) {
				AccessibilityHelper.first.focus();
			}
			// If current focused element is last focusable element in lightbox, then focus 1st focusable element in lightbox
			else if(document.activeElement.classList.contains("lightbox__last-focus")) {
				AccessibilityHelper.first.focus();
			}
			// If current focused element is first focusable element in lightbox, then focus last focusable element in lightbox
			else if(document.activeElement.classList.contains("lightbox__first-focus")) {
				AccessibilityHelper.last.focus();
			}
		}
		else if(event.keyCode == 27) { // Escape pressed
			if(AccessibilityHelper.closeOnEsc) {
				AccessibilityHelper.first.click();
			}
		}
	}
}
export default AccessibilityHelper;