import Tools from '../tools/Tools';
class Hotspots{
	constructor(){
		this.spots = [];
		this.zoomTL = null;
		this.hideTL = null;
		this.overlayOpened = false;
		this.canClick = true;
		this.panelSection = "main";
		this.bg = $(".hotspots--bg");
		this.overlay = $(".hotspots-overlay");
		this.panel = $(".hotspots-panel");
		this.fn = ['init','buildSpotArray','renderSpots','clicked','zoomBG','fetchImg','imgLoaded','switchPanel','keyup','unzoom'];
		Tools.autoBind(this,this.fn);
		this.init();
	}

	init(){
		if($(".aisinai").length || $(".nisula").length || $(".kakawin").length){
			this.buildSpotArray();
			this.renderSpots();
		}

		$(".spots a").on('click',this.clicked);
	}	


	buildSpotArray(){
		$(".hotspots li").each((i,el)=>{
			var t = {};
			t.id = i;
			t.parent = el;
			t.element = el.childNodes[1];
			t.coords = {};
			t.coords.x = parseFloat(el.getAttribute('data-x'));
			t.coords.y = parseFloat(el.getAttribute('data-y'));
			t.coords.direction = ($(t.parent).hasClass('left')) ? "left" : "right";
			t.target = null;

			this.spots.push(t);
		});
	}

	renderSpots(){
		for(var i = 0; i < this.spots.length; i++){
			$(this.spots[i].parent).attr('data-id', this.spots[i].id);
			$(this.spots[i].element).find('.hidden-sr').text($(".hotspots-viewport").attr('data-closedText') + " ");
			this.spots[i].parent.style.left = this.spots[i].coords.x + "%";
			this.spots[i].parent.style.top = this.spots[i].coords.y + "%";
			var x = (this.spots[i].coords.direction === "left") ? "-100%" : "0%";
			TweenMax.to(
				this.spots[i].parent,
				.75,
				{
					scale:1,
					x:x,
					y:"-50%",
					ease:Quint.easeOut,
					delay:i / 5
				}
			);
		}
	}

	clicked(e){
		e.preventDefault();
		if($(".aisinai").length){
			return false;
		}
		if(this.canClick){
			if(!this.overlayOpened){
				var x, y,id;
				x = $(e.target).parent().attr('data-x');
				y = $(e.target).parent().attr('data-y');
				id = $(e.target).parent().attr('data-id');
				this.panelSection = $(e.target).parent().attr('data-target');
				this.zoomBG(x,y,2.5,id);
				if($(".kakawin").length || $(".aisinai").length){
					TweenMax.to($('.hotspots-panel'), 1.5, {css:{autoAlpha:1}, ease:'Cubic.easeOut'});
					TweenMax.to($('.hotspots .defaultTxt'), 1.5, {css:{autoAlpha:0}, ease:'Cubic.easeOut'});
				} 
			}else{
				$(e.target).blur();
				this.unzoom();
				if($(".kakawin").length) TweenMax.to($('.hotspots-panel'), 1.5, {css:{autoAlpha:0}, ease:'Cubic.easeOut'});
				if($(".aisinai").length){
					TweenMax.to($('.hotspots-panel'), 1.5, {css:{autoAlpha:0}, ease:'Cubic.easeOut'});
					TweenMax.to($('.hotspots .defaultTxt'), 1.5, {css:{autoAlpha:1}, ease:'Cubic.easeOut'});
				} 
			}
		}
	}

	unzoom(){
		this.canClick = false;
		this.hideTL.eventCallback('onReverseComplete',()=>{
			this.canClick = true;
		});

		this.zoomTL.eventCallback('onReverseComplete',()=>{
			this.hideTL.reverse();

		});
		this.zoomTL.reverse();
		this.panelSection = "main";
		this.switchPanel();
		$('.hotspots .close').removeClass('close');
		this.overlayOpened = false;
	}

	zoomBG(x,y,amount,id){
		x = parseFloat(x);
		y = parseFloat(y);

		this.bg[0].style.transformOrigin = `${x}% ${y}%`;
		this.overlay[0].style.transformOrigin = `${x}% ${y}%`;
		this.zoomTL = this.hideTL = null;
		this.zoomTL = new TimelineMax({
			onComplete:()=>{
				this.canClick = true;
				$('.hotspots .loading').addClass('close').removeClass('loading');
			}
		});

		this.hideTL = new TimelineMax();
		this.zoomTL.to(
			this.bg,
			1.6,
			{
				ease:Power2.easeIn,
				scale:amount
			},
			0
		);
		this.zoomTL.to(
			this.overlay,
			2.5,
			{
				autoAlpha:1,
				scale:1,
				ease:Power3.easeInOut,
				delay:0,
			},
			0
		);
		this.zoomTL.pause();

		$('.hotspots [data-id="'+id+'"]').addClass('loading');
		this.fetchImg($('.hotspots [data-id="'+id+'"]').attr('data-img'));
		let modifier = 0;
		for(var i = 0; i < this.spots.length; i++){
			if(this.spots[i].id === ~~id){
				modifier = -1;
			}else{
				// $(this.spots[i].element).attr('aria-hidden','true');
				this.hideTL.to(
					this.spots[i].parent,
					0.5,
					{
						scale:0,
						ease:Quint.easeInOut
					},
					(i - modifier) / 10
				);
			}
		}

	}

	fetchImg(src){
		let img = new Image();
		img.onload = this.imgLoaded;
		img.src = src;
	}
	imgLoaded(e){
		this.overlay[0].childNodes[1].src = e.target.src;
		this.canClick = false;
		this.switchPanel(this.panelSection);
		this.zoomTL.play();
		this.overlayOpened = true;
	}

	keyup(e){
		let key = e.which;
		if(key === 27){
			this.unzoom();
		}
	}

	switchPanel(section = "main"){
		TweenMax.to(
			$('.hotspots [data-state="active"]'),
			1.5,
			{
				// x:"-100%",
				autoAlpha:0,
				ease:Power4.easeInOut,
				onComplete:()=>{
					$('.hotspots [data-state="active"]').attr('aria-hidden','true').attr('data-state','inactive');
					if(section !== "main"){
						$('.hotspots-viewport li:not([data-target="'+section+'"]) a').attr('tabindex',-1);
						$('.hotspots-viewport li[data-target="'+section+'"] a').find('.hidden-sr').text($(".hotspots-viewport").attr('data-openedText') + " ");
						window.addEventListener("keyup",this.keyup);
					}else{
						$('.hotspots-viewport a').attr('tabindex',0);
						$('.hotspots-viewport a').find('.hidden-sr').text($(".hotspots-viewport").attr('data-closedText') + " ");
					}
					$("#"+section).attr('data-state','active').attr('aria-hidden','false').focus();
					TweenMax.set($('.hotspots [data-state="active"]'),{autoAlpha:0});
					TweenMax.to(
						$('.hotspots [data-state="active"]'),
						1,
						{
							autoAlpha:1,

							ease:Power4.easeInOut
						}
					);
				}
			}
		);
	}

}
export default Hotspots;