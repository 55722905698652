class LazyIMG{
	constructor(data){
		this.data = data;
		var img = new Image();
		img.onload = this.onload.bind(this);
		img.src = data.highres;
	}
	onload(){
		var target = $('[data-id="'+this.data.id+'"]');
		if(target.is('img')){
			target[0].src = this.data.highres;
		}else{
			target[0].style.backgroundImage = 'url(' +this.data.highres+ ')';
		}
		target.addClass('loaded');

		target.addClass('lazy-ready');
	}
}
export default LazyIMG;	