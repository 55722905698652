
class SideNav {
	constructor(){

		$(window).on("resize",this.resize);
		$(window).on("scroll",this.scrollEvent);

        this.resize();
        this.scrollEvent();
	}

	scrollEvent(){
		var scrollP = $(window).scrollTop();
		var heightContent = $("article").height();
		var heightSideNav = $("#page-content aside > nav").height();
		var startP = $(".title-banner").height()+$("header").height()-200;
		var maxP = heightContent-heightSideNav+startP;

		if($(window).width()>768){
			if(scrollP>=startP){
				if(scrollP>=maxP) $("#page-content aside > nav").css({"position":"absolute", "top":maxP-startP});
				else $("#page-content aside > nav").css({"position":"fixed", "top":"135px"});
			}else{
				$("#page-content aside > nav").css({"position":"relative", "top":""});
			}
		}else{
			$("#page-content aside > nav").css({"position":"relative", "top":""});
		}
	}

	resize(){
		if($(window).width()>768){
			$("aside#navigation").height($("aside#navigation").siblings("article").height());
			$("#page-content aside > nav").css({"position":"fixed"});
		}else{
			$("aside#navigation").css("height","auto");
			$("#page-content aside > nav").css({"position":"relative"});
		} 
	}

}
export default SideNav;