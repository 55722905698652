import Tools from '../tools/Tools';

class Home {
	constructor(){
		// Global Variables

		this.windowScoll = this.windowScoll.bind(this);
		this.logoHeader = this.logoHeader.bind(this);
		
		// Flags

		this.fn = ['init', 'animateBackgrounds'];
		Tools.autoBind(this,this.fn);
		$(window).on("scroll",this.windowScoll.bind(this));

		this.init();
	}

	init(){
		if($('.backgrounds li').length > 1){
			console.log("START BACKGROUND");
			this.animateBackgrounds();
		}

		$('header h1.logo').css({"opacity":"0","visibility":"hidden"});
	}

	windowScoll(){
		this.logoHeader();
	}

	animateBackgrounds(){
		var current, next, pauseDuration, elementsList;
		elementsList = $(".backgrounds li");
		current = $(".backgrounds .active");
		next = current.next().length > 0 ? current.next()[0] : elementsList[0];
		pauseDuration = 5;
		TweenMax.to(
			current,
			3,
			{
				delay:pauseDuration,
				autoAlpha:0,
				ease:Power1.easeInOut,
				onComplete:()=>{
					current.removeClass('active');
				}
			}
		);

		TweenMax.to(
			next,
			3,
			{
				delay:pauseDuration,
				autoAlpha:1,
				ease:Power1.easeInOut,
				onComplete:()=>{
					next.classList.add('active');
					this.animateBackgrounds();
				}
			}
		);

	}

	logoHeader(){
		if($(window).scrollTop()<=300 && $("section#home").length) TweenMax.to($('header h1.logo'), 0.4, {css:{autoAlpha:0}});
		else TweenMax.to($('header h1.logo'), 1.0, {css:{autoAlpha:1}});
	}

}
export default Home;