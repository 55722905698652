import slidescreen from './partials/SlideScreen';
import DeviceInfo from './tools/DeviceInfo';
import AccessibilityHelper from './tools/AccessibilityHelper';
import Forms from './views/forms';
import Galerie from './views/Galerie';
import Glossaire from './views/Glossaire';
import DefaultView from './views/Default';
import Header from './partials/Header';
import SideNav from './partials/SideNav';
import Videos from './partials/Videos';
import Hotspots from './partials/Hotspots';
import detailsOverlay from './partials/detailsOverlay';
import LazyBg from './tools/LazyBg';
var count = 0;
/**
 * 
 **/
class Main {

	constructor () {
		
		$( this.init.bind(this) );

	}

	init () {
		window.mouse = {};
		window.addEventListener('mousemove',this.setMousepos);
		window.addEventListener('click',this.closeSearch);
		window.addEventListener('keydown',this.tabFocus);

		this.$searchbutton = document.querySelector('.triggerCheckbox');
		this.$searchbutton.addEventListener('click',this.searchbutton);

		const classes = {
			home: require('./views/Home'),
			nisula: require('./views/Nisula'),
			aisinai: require('./views/Aisinai'),
			kakawin: require('./views/Kakawin'),
			kejimkujik: require('./views/Kejimkujik'),
			qajartalik: require('./views/Qajartalik'),
			decouvrir: require('./views/Decouvrir')
		};

		// Init views
		let view = ($('[data-view]').attr('data-view') || "default").toLowerCase();
		if(classes[view]){
			this.view = new classes[view].default;
		}else{
			//console.warn(`The view "${view}" was not found. Your site may experience issues`);
		}

		this.header = new Header();

		// AccessibilityHelper.putFocusInView();

		if($(".container-slidescreen").length){
			this.slidescreen = new slidescreen();
		}

		if($("aside#navigation").length){
			this.SideNav = new SideNav();
		}

		if($(".content form").length){
			this.forms = new Forms();
		}

		if($(".slider").length){
			this.Galerie = new Galerie();
		}

		if($(".open-overlay").length){
			this.detailsOverlay = new detailsOverlay();
		}

		if($(".openZoom").length){
			$(".openZoom").magnificPopup({
				type:'image',
				removalDelay: 300,
				mainClass:'mfp-fade',
				closeMarkup:'<button title="%title%" type="button" class="mfp-close"><span aria-hidden="true">&#215;</span><span class="hidden-sr">Fermer</span></button>',
				callbacks:{
					open:()=>{
						$(".mfp-figure figure").attr('tabindex',0);

						console.log("OPEEEEN ZOOOOM");

						AccessibilityHelper.lockTab($(".mfp-figure"),$(".mfp-figure button"), $(".mfp-figure figure"));
					}
				}
			});
		}

		if($(".video").length){
			this.Videos = new Videos();
		}

		
		this.focus = false;
		$("label.triggerCheckbox").on('focus',(e)=>{
			e.target.addEventListener('keyup',this.bindSpace.bind(this, e.target));
		});
		$("label.triggerCheckbox").on('blur',(e)=>{
			e.target.removeEventListener('keyup',this.bindSpace.bind(this, e.target));
		});
		$(".searchField").on('blur',(e)=>{
			$("#searchToggle").prop("checked", false);
		});

		$(window).on('load',()=>{
			if($(".lazy-bg").length){
				this.LazyBg = new LazyBg();
			}

			if($(".hotspots").length){
				this.Hotspots = new Hotspots();
			}

			this.DefaultView = new DefaultView();
		});

		if ($(window).width() > 960) {
			//truc qui centre la window quand on tab sur les shape
			$('.shape').focusin(function(e) {
				var target = $(this);
				$('html,body').animate({
					scrollTop: target.offset().top - 200
				}, 100);
			});

			$('.play.api.nomask').focusin(function(e) {
				var target = $( e.target );
				$(target).keydown(function( event ) {
					if ( event.which == '13' ) {
						event.preventDefault();
						target.find( ".play-btn" ).click();
					}
				});
			});

			$('.anchorNav a').focusin(function(e) {
				var target = $( e.target );
				$(target).keydown(function( event ) {
					if ( event.which == '13' ) {
						target.click();
					}
				});
			});

		}

	}

	tabFocus(e){
		var focused = document.activeElement;
		count++;

		if(e.key == 'Tab') {
			console.log(focused);
			//focused.classList.add('focused' + count);
		}
		
		if(document.activeElement == document.querySelector('.fakeCheckBox-anchor')){
			if(e.key == 'Enter') {
				e.preventDefault();
				if($('.checkbox input').is(':checked')){
					$(".fakeCheckBox").removeClass("checked");
					$('.checkbox input').prop("checked", false);
				} else {
					$(".fakeCheckBox").addClass("checked");
					$('.checkbox input').prop("checked", true);
				} 
			}
		}
	}

	closeSearch(e){
		if( !e.target.classList.contains('searchToggle') &&
			!e.target.classList.contains('searchField') &&
			!e.target.classList.contains('triggerCheckbox') &&
			!e.target.classList.contains('submitsearch') &&
			!e.target.classList.contains('searchForm') ) {
				document.querySelector('.searchForm').style.display = 'none';
		}
	}

	searchbutton(){
		if(document.querySelector('.searchForm').style.display == 'none'){
			document.querySelector('.searchForm').style.display = 'block';
		}else{
			document.querySelector('.searchForm').style.display = 'none';
		}
	}

	setMousepos(e){
		window.mouse.x = e.pageX;
		window.mouse.y = e.pageY;
	}

	bindSpace(target,e){
		e.preventDefault();
		var inputTarget = $('input#' + $(target).attr('for'));
		inputTarget.prop("checked", true);
		return false;
	}

}

var app = new Main();