import Tools from '../tools/Tools';
import AccessibilityHelper from '../tools/AccessibilityHelper';
class Galerie{
	constructor(){
		this.prev = $(".btns .prev");
		this.next = $(".btns .next");
		this.zoom = $(".btns .zoom");
		this.swipeHtml = $(".pswp")[0];
		this.activeSlider = null;
		this.options = {
			closeOnScroll:false,
			index:0
		};
		this.fn = ['init','buildArray','openSlider','initNav','switchSlide'];
		Tools.autoBind(this,this.fn);

		this.sliders = [];

		this.init();

	}
	init(){

		// checkHash();
		this.buildArray();
		this.initNav();
	}
	initNav(){
		this.prev.on('click',(e)=>{
			this.switchSlide(-1,e);
		});

		this.next.on('click',(e)=>{
			this.switchSlide(1,e);
		});
		this.zoom.on('click',(e)=>{
			e.preventDefault();

			let slider, slide, target;
			target = $(e.target).closest('.btns').attr('data-controls-for');

			slider = $('.slider[data-id="'+target+'"]');
			slide = ~~slider.find('li.active').index()

			this.openSlider(~~slider.attr('data-id'), slide);
		});
		
		this.zoom.on('keypress',(e)=>{
			e.preventDefault();

			let slider, slide, target;
			target = $(e.target).closest('.btns').attr('data-controls-for');

			slider = $('.slider[data-id="'+target+'"]');
			slide = ~~slider.find('li.active').index()

			this.openSlider(~~slider.attr('data-id'), slide);
		});

	}
	switchSlide(dir,e){
		e.preventDefault();
		var targetList, target,nb, current;
		targetList = $(e.target).closest('.btns').prev().find('li');
		current = $(e.target).closest('.btns').prev().find('li.active').index();
		nb = targetList.length;

		if(current + dir > nb - 1){
			target = 0;
		}else if(current + dir < 0){
			target = nb - 1;
		}else{
			target = current + dir;
		}

		targetList.removeClass('active');
		targetList.eq(target).addClass('active');

	}
	buildArray(){
		$(".slider").each((i,el)=>{
			var t = [];
			$(el).find('li').each((i,el)=>{
				var temp, _this,bg,author;
				temp = {};	
				_this = $(el);
				bg = _this[0].style.backgroundImage;
				bg = bg.replace('url("','');
				bg = bg.replace('")','');
				temp.src = _this.attr('data-src');
				temp.msrc = bg
				if(_this.attr('data-author')){
					author = "<div class='author'>"+_this.attr('data-author')+"</div>";
				}else{
					author = "";
				}
				temp.title = _this.attr('data-caption') + author;
				temp.w = ~~_this.attr('data-width');
				temp.h = ~~_this.attr('data-height');
				this.author = "Benoit";
				t.push(temp);
			});
			var id = ~~$(el).attr('data-id');
			this.sliders[id] = {};
			this.sliders[id].PhotoSwipe = null;
			this.sliders[id].slides = t;
		});
	}

	openSlider(slider,slide){
		const currentActiveElement = document.activeElement; // Save active element to focus on lightbox closed
		var slides = this.sliders[slider].slides;
		this.activeSlider = slider;
		this.options.index = slide;
		this.options.galleryUID = slider;
		this.options.focus = false;
		this.sliders[slider].PhotoSwipe = new PhotoSwipe(this.swipeHtml, PhotoSwipeUI_Default, slides, this.options);
		this.sliders[slider].PhotoSwipe.init();

		const hasOneSlide = $(".pswp .pswp__ui--one-slide");
		if(hasOneSlide[0]){
			AccessibilityHelper.lockTab($(".pswp"), $(".pswp .pswp__button--close"), $(".pswp .pswp__button--zoom"));
		}else{
			AccessibilityHelper.lockTab($(".pswp"), $(".pswp .pswp__button--close"), $(".pswp .pswp__button--arrow--right"));
		}
		
		this.sliders[slider].PhotoSwipe.listen('destroy',()=>{
			
			AccessibilityHelper.unlockTab();

			currentActiveElement.focus();
			this.activeSlider = null;
		});
	}
}
export default Galerie;