import Tools from '../tools/Tools';
class detailsOverlay{
	constructor(){
		this.overlay = $("#details");
		this.currentTarget = null;
		this.scrollTreshold = 300;
		this.scrollPos = null;

		this.fn = ['init','openOverlay','closeOverlay','keyUp'];
		Tools.autoBind(this,this.fn);

		this.init();
	}

	init(){
		$(".open-overlay").on('click',this.openOverlay);
	}

	openOverlay(e){
		console.log("OPEN OVERLAY");
		
		e.preventDefault();
		this.scrollPos = $(".pereLaure").offset().top;
		let target = $(e.target).attr('data-target');
		this.currentTarget = target;
		this.overlay.addClass('visible').attr('aria-hidden','false');
		$("#"+target).parent().css("top",this.scrollPos)
		$("#"+target).addClass('visible');
		setTimeout(function(){
			$("#"+target).focus();
		},1500)

		this.overlay.find('.close').one('click',this.closeOverlay);

		window.addEventListener('keyup',this.keyUp);
	}
	keyUp(e){
		let key = e.which;
		if(key === 27){
			this.closeOverlay();
		}
	}
	closeOverlay(e){
		if(e){
			e.preventDefault();
		}

		window.removeEventListener('keyup',this.keyUp);

		this.overlay.removeClass('visible').attr('aria-hidden','true');
		this.overlay.find('.visible').removeClass('visible')

		$('.open-overlay[data-target="'+this.currentTarget+'"]').focus();
	}
}
export default detailsOverlay;