if ($('body').hasClass('page-template-page-glossaire-php')) {
	setTimeout(function() {
		$('#menu-item-267 a').focus();
	}, 1000);
}


$(".glossaire-anchor").click(function(e) {	
	setTimeout(function() { $('.glossaire #backtomenu').focus() }, 1000);
	var id = $(this).attr('href');
	$('html, body').animate({
    	scrollTop: $(id).offset().top - 70
	}, 1000);
});

$(".glossaire #backtomenu").click(function(e) {	
	console.log('clicked on tha ass');
	setTimeout(function() { $('.glossaire-anchor').first().focus() }, 1000);
	$('html, body').animate({
    	scrollTop: 0
	}, 1000);
});

$(".nisula-anchor").click(function(e) {
	var id = $(this).attr('href');
	$('html, body').animate({
    	scrollTop: $(id).offset().top - 120
	}, 1000);
});