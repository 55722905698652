class Tools {

	/**
	 * Get Params from url
	 * @return object
	 */
	static getParams() {

		let query_string = {};
		let query = window.location.search.substring(1);
		let vars = query.split("&");

		for (let i=0;i<vars.length;i++) {
		  var pair = vars[i].split("=");

		  if (typeof query_string[pair[0]] === "undefined") {
		      query_string[pair[0]] = pair[1];

		  } else if (typeof query_string[pair[0]] === "string") {
		      var arr = [ query_string[pair[0]], pair[1] ];
		      query_string[pair[0]] = arr;

		  } else {
		      query_string[pair[0]].push(pair[1]);
		  }
		}
		
		return query_string;
	}

	/**
	 * Auto bind -> this
	 * @param  object: targeted object (this)
	 * @param  array: functions which need binding
	 */
	static autoBind( object, arrayFn ) {

		if( !object || !arrayFn || arrayFn.length < 1) return;

		for (let i = 0; i < arrayFn.length; i++) {

		    const fn = arrayFn[i];

		    if(!object[fn]) console.error('Autobind: Undefined function !');

		    object[fn] = object[fn].bind(object);
		}
	}

	/**
	 * Auto unbind -> this
	 * @param  object: targeted object (this)
	 */
	static autoUnBind( object, arrayFn ) {

		if( !object || !arrayFn || arrayFn.length < 1) return;

		for (let i = 0; i < arrayFn.length; i++) {

		    const fn = arrayFn[i];

		    if(object[fn] && typeof object[fn] === 'function' ) object[fn] = null;
		}
	}

	static getScrollTop() {
		return (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0);
	}

	static getSupportedPropertyName(property) {
		const prefixes = ['', 'ms', 'Webkit', 'Moz', 'O'];
		
		for(let i = 0; i < prefixes.length; i++) {
			let prefix = prefixes[i];
			property = prefix === '' ? property : property.charAt(0).toUpperCase() + property.substring(1).toLowerCase();
			let prop = prefix+property;
			
			if(typeof document !== 'undefined' && typeof document.body.style[prop] != "undefined") 
				return prop;
		}
		
		return null;
	}

	/* Request Animation Frame */
	static raf() {
		return 	window.requestAnimationFrame ||
				window.webkitRequestAnimationFrame ||
				window.mozRequestAnimationFrame ||
				window.msRequestAnimationFrame ||
				window.oRequestAnimationFrame ||
				function(callback){ window.setTimeout(callback, 1000/60); };
	}

	static clearRaf(id) {
		return 	window.cancelAnimationFrame(id) ||
				window.clearTimeout(id);
	}

	static interval(value, min, max) {
		return Math.min(Math.max(value, min), max);
	}

	static inViewport(el, marge=0) {
		return (el.offset().top - window.innerHeight + marge < 0 && el.offset().top + el.offset().height > 0) ? true : false;
	}

	static isFullyVisible(el){
		var t,h;
		t = el.getBoundingClientRect().top;
		h = el.offsetHeight;
		return {
			visible:(t > 0 && t + h < window.innerHeight) ? true : false,
			biggerThanScreen:(h > window.innerHeight) ? true : false
		}
	}

	/**
	 * Suffle Array
	 * @return array
	 */
	static shuffleArray(o) {
    	for(let j, x, i = o.length; i; j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
    	return o;
	}


}


export default Tools;