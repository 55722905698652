import Tools from '../tools/Tools';

class Kejimkujik {
	constructor(){
		$(window).on("resize",this.resize);
		$(".slider.singleFocus li img").on("click",this.sliderToGallery);
		$(".slider.swipe li img").on("click",this.sliderToGallery2);
		$(".squareImg").on("click",this.sliderToGallerySquare);
		$(".singleFocusCircle").on("click",this.sliderToGalleryCircle);
		$(".kejimkujik .anchorNav ul li a").on("click",this.anchorAnim);
		$(".sliderBoxed .btns a.next").on("click",this.sliderAdapNext);
		$(".sliderBoxed .btns a.prev").on("click",this.sliderAdapPrev);
		this.init();
		this.resize();
	}

	init(){
		window.addEventListener('scroll',this.scroll);
	}
	sliderToGallery(){
		$(".toTrigger").trigger("click");
	}
	sliderToGallery2(){
		$(this).parents(".swipe").siblings(".btns").find("a").trigger('click');
	}
	sliderToGallerySquare(){
		$(this).siblings(".btns").find("a").trigger('click');
	}
	sliderToGalleryCircle(event){
		if (!$(event.target).is('a')) {
	        $(this).find("a").trigger('click');
	    }
	}

	//Worst Hack ever...Sorry
	sliderAdapNext(){
		if($(window).width()<=768){
			var activeIndex = $(".sliderBoxed ul li.active").index()+2;
			if (activeIndex===16) activeIndex=1
			else activeIndex = $(".sliderBoxed ul li.active").index()+2;
			var heightC = $(".sliderBoxed ul li:nth-child("+activeIndex+") div:first-child").height() + $(".sliderBoxed ul li:nth-child("+activeIndex+") div:last-child").height();
			$(".sliderBoxed ul").height(heightC);
		}else{
			$(".sliderBoxed ul").css("height","");
		}
	}
	sliderAdapPrev(){
		if($(window).width()<=768){
			var activeIndex = $(".sliderBoxed ul li.active").index();
			if (activeIndex===0) activeIndex=15
			else activeIndex = $(".sliderBoxed ul li.active").index();
			var heightC = $(".sliderBoxed ul li:nth-child("+activeIndex+") div:first-child").height() + $(".sliderBoxed ul li:nth-child("+activeIndex+") div:last-child").height();
			$(".sliderBoxed ul").height(heightC);
		}else{
			$(".sliderBoxed ul").css("height","");
		}
	}

	scroll(e){

		var newScroll = $(window).scrollTop();

	    $('.kejimkujik .toAnchor').each(function(){
            if($(this).index() >= 0 && newScroll >= $(this).offset().top - 107 && newScroll <= ($(this).offset().top + $(this).height()) - 107 && $('.kejimkujik .anchorNav ul').attr('id') != $(this).attr('id')){
                var block = $(this).attr('id');
                $('.kejimkujik .anchorNav ul').attr('id', block);

                if(($('.kejimkujik .anchorNav ul').attr("id") == "noAnchor")){
                    $(".kejimkujik .anchorNav ul li a").removeClass("active");
                }

                if(($('.kejimkujik .anchorNav ul').attr("id") == "Slider")){
                    $(".kejimkujik .anchorNav ul li a").removeClass("active");
                    $(".kejimkujik .anchorNav ul li:nth-child(1) a").addClass("active");
                }

                if(($('.kejimkujik .anchorNav ul').attr("id") == "Roche")){
                    $(".kejimkujik .anchorNav ul li a").removeClass("active");
                    $(".kejimkujik .anchorNav ul li:nth-child(2) a").addClass("active");
                }

                if(($('.kejimkujik .anchorNav ul').attr("id") == "Technique")){
                    $(".kejimkujik .anchorNav ul li a").removeClass("active");
                    $(".kejimkujik .anchorNav ul li:nth-child(3) a").addClass("active");
                }

                 if(($('.kejimkujik .anchorNav ul').attr("id") == "George")){
                    $(".kejimkujik .anchorNav ul li a").removeClass("active");
                    $(".kejimkujik .anchorNav ul li:nth-child(4) a").addClass("active");
                }
            }
        });

	}

	anchorAnim(e){
		e.preventDefault();
		var element = $(e.currentTarget).parent().index()+1;
		var scrollP = $(".kejimkujik").find("[data-index='"+element+"']").offset().top - 100;
		var curScroll = {y:$(window).scrollTop()};
        TweenMax.to(curScroll, 0.5, {y:scrollP, onUpdate:()=> { window.scrollTo(0, curScroll.y); }, ease:'cubic.easeOut'});
	}

	resize(){
		if($(window).width()<=768){
			var heightC = $(".sliderBoxed ul li.active div:first-child").height() + $(".sliderBoxed ul li.active  div:last-child").height();
			var heightBTN = $(".sliderBoxed ul li.active div:first-child").height();
			$(".sliderBoxed ul").height(heightC);
			$(".sliderBoxed .btns").css("top",heightBTN);
		}else{
			$(".sliderBoxed ul").css("height","");
			$(".sliderBoxed .btns").css("top","");
		}
	}

}
export default Kejimkujik;