class Header {
	constructor() {
		// Bindings
		this.focusInside = this.focusInside.bind(this);
		this.onTriggerFocus = this.onTriggerFocus.bind(this);
		this.onTriggerBlur = this.onTriggerBlur.bind(this);
		this.onFocusOnFirst = this.onFocusOnFirst.bind(this);
		this.onFocusOnLast = this.onFocusOnLast.bind(this);

		// Elements
		this.view = document.querySelector("header");
		this.subnavTrigger = this.view.querySelector('#explorebtn');
		this.subnavLinks = this.view.querySelectorAll(".level-2 a");
		this.subnavFirstFocus = this.view.querySelector(".subnav__first-focus");
		this.subnavLastFocus = this.view.querySelector(".subnav__last-focus");
		this.nextLink = this.subnavTrigger.parentElement.nextElementSibling.querySelector("a");

		// Events
		this.subnavTrigger.addEventListener('keydown', this.focusInside);
		this.subnavTrigger.addEventListener('focus', this.onTriggerFocus);
		this.nextLink.addEventListener('focus', this.onTriggerBlur);

		this.subnavFirstFocus.addEventListener('focus', this.onFocusOnFirst);
		this.subnavLastFocus.addEventListener('focus', this.onFocusOnLast);
	}

	focusInside(e) {
		if(e.key == 'Enter') {
			// Enable focus on subnav links
			for (var i = 0; i < this.subnavLinks.length; i++) {
				this.subnavLinks[i].setAttribute("tabindex", "0");
			}
			this.subnavFirstFocus.setAttribute("tabindex", "0");
			this.subnavLastFocus.setAttribute("tabindex", "0");

			// Focus on first subnav link
			this.subnavLinks[0].focus();
		}
	}

	onTriggerFocus() {
		// Disable focus on subnav links
		for (var i = 0; i < this.subnavLinks.length; i++) {
			this.subnavLinks[i].setAttribute("tabindex", "-1");
		}
		this.subnavFirstFocus.setAttribute("tabindex", "-1");
		this.subnavLastFocus.setAttribute("tabindex", "-1");
	}
	onTriggerBlur() {
		// Disable focus on subnav links
		for (var i = 0; i < this.subnavLinks.length; i++) {
			this.subnavLinks[i].setAttribute("tabindex", "-1");
		}
		this.subnavFirstFocus.setAttribute("tabindex", "-1");
		this.subnavLastFocus.setAttribute("tabindex", "-1");
	}

	onFocusOnFirst() {
		this.subnavTrigger.focus();
	}
	onFocusOnLast() {
		this.nextLink.focus();
	}
}

export default Header;