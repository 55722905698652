 import EventsManager from '../tools/events/EventsManager';
 import NSFWEvents from '../tools/events/NSFWEvents';
 
class SlideScreen {

	constructor () {

		this.divisor = document.getElementById("divisor");
    	this.handle = document.getElementById("handle");
    	this.slider = document.getElementById("slider");

		this.slider.addEventListener("input", this.moveDivisor);

		window.onload = function() {
			this.moveDivisor();
		}.bind(this);

		this.moveDivisor = this.moveDivisor.bind(this);
		this.isTouch = this.isTouch.bind(this);

		EventsManager.on(NSFWEvents.SliderScreen.TOUCH, this.isTouch);
		
	}

	moveDivisor(pageX) {
		EventsManager.emit(NSFWEvents.SliderScreen.TOUCH, { item:this.view });
	}

	isTouch() {
		this.handle.style.left = slider.value+"%";
		this.divisor.style.width = slider.value+"%";
	}

}

export default SlideScreen;