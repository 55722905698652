class Decouvrir {
	constructor(){
		this.navLinks = document.querySelectorAll("#navigation a");
		for (let i = 1; i < this.navLinks.length; i++) { // SKIP 1st LINK
			if(this.navLinks[i].getAttribute("href") === window.location.href) {
				this.navLinks[i].focus();
			}
		}
	}

}
export default Decouvrir;