// import SoundManagerEvents from 'nsfw/sound/events';
// import GoogleMapEvents from 'nsfw/geo/gmaps/events';
// import VimeoPlayerEvents from 'nsfw/video/vimeo/events';
// import YoutubePlayerEvents from 'nsfw/video/youtube/events';
// import RouterEvents from 'nsfw/net/router/events';

/**
 * 
 * NSFW Core Events
 *
 * v1.2
 */

const NSFWEvents = {

	// Router
	// Router: RouterEvents,

	// // SoundManager
	// Sound: SoundManagerEvents,

	// // GoogleMap 
	// GoogleMap: GoogleMapEvents,

	// // VimeoPlayer
	// VimeoPlayer: VimeoPlayerEvents,

	// // YoutubePlayer
	// YoutubePlayer: YoutubePlayerEvents,

	SliderScreen : {
        MOUSEDOWN   : "mousedown",
        MOUSEUP     : "mouseup",
        TOUCH		: "touch"
    },

};

export default NSFWEvents;