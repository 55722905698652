import LazyIMG from './LazyIMG';
class LazyBg{
	constructor(){
		this.bgs = [];
		this.guid = ()=>{
			function s4(){
				return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
			}
			return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
		};

		this.init = this.init.bind(this);
		this.buildArray = this.buildArray.bind(this);
		this.preloadImg = this.preloadImg.bind(this);

		this.init();
	}
	init(){
		this.buildArray();
		this.preloadImg();
	}
	buildArray(){
		$(".lazy-bg").each((i,el)=>{
			var t = {};
			t.highres = el.dataset.highres;
			t.id = this.guid();
			el.dataset.id = t.id;

			this.bgs.push(t);
		});
	}

	preloadImg(){
		for(var i = 0; i < this.bgs.length; i++){
			var img = new LazyIMG(this.bgs[i]);
		}
	}
}
export default LazyBg;