import AccessibilityHelper from '../tools/AccessibilityHelper';

class DefaultView{
	constructor(){
		this.resize = this.resize.bind(this);
		this.onAudioPlayed = this.onAudioPlayed.bind(this);
		this.onAudioPaused = this.onAudioPaused.bind(this);

		this.heightBox = 0;
        
        $(window).on("resize",this.resize);
        this.resize();

        // Events
		$("header nav li").on("click", this.mobileMenu.bind(this));
		$(".audioPlayer a").on("click", this.showPLayer.bind(this));
		$(".ctaTranscript").on("click", this.openAudioTranscript.bind(this));
		$(".popUpTranscript .close").on("click", this.closeTranscript.bind(this));
		
		$(".ctaShow").on("click", this.toggleVideoTranscript.bind(this));

		$(".decouvrir .anchor > a, .explorer .anchor > a").on("click", this.anchorScroll.bind(this));

		this.addTabText();

		// Create audios
		audiojs.events.ready(() => {
    		this.audios = audiojs.createAll();
      	});

      	$(".openSingleZoom").on('click',this.tiggerOverlay);

		this.ScrolltoContent = this.ScrolltoContent.bind(this);
      	this.scrolltocontent = document.querySelector("#scrolltocontent");
      	this.scrolltocontent.addEventListener('keydown', this.ScrolltoContent);
	}

	ScrolltoContent(e) {
		if(e.key == 'Enter'){
			if(document.querySelector(".play")){
				var playbtn = document.querySelectorAll(".play");
				playbtn[0].focus();
			}else{
				var links = document.querySelectorAll("#content a");
				links[0].focus();
				console.log(links[0]);
			}
		}
	}

	resize(){
		if($(".openSubMobile").length){
			this.heightBox = $(".openSubMobile >div").height();
			$(".openSubMobile").height(this.heightBox);
		} 
	}

	openAudioTranscript(e){
		console.log("AUDIO TRANSCRIPT");

		let elId = $(e.currentTarget).attr('data-label')
		$("body").css("overflow","hidden");

		$(".popUpTranscript")[0].setAttribute("data-current-transcript", elId);

		TweenMax.to($(".popUpTranscript"), 0.2, {css:{autoAlpha:1}, ease:'Cubic.easeOut', onComplete:()=>{
			const audioPlayer = $(`.popUpTranscript #${elId} .audioPlayer a`);
			if(audioPlayer[0])
				AccessibilityHelper.lockTab($(".popUpTranscript"), $(".popUpTranscript .close"), audioPlayer, true);
			else
				AccessibilityHelper.lockTab($(".popUpTranscript"), $(".popUpTranscript .close"), $(".popUpTranscript .close"), true);
		}});
		TweenMax.to($(".popUpTranscript #"+elId), 0.2, {css:{autoAlpha:1}, ease:'Cubic.easeOut'});
	}

	closeTranscript(e){
		e.preventDefault();

		console.log("CLOSE TRANSCRIPT");
		const currentTranscript = $(".popUpTranscript")[0].getAttribute("data-current-transcript");

		// Stop audio
		if(currentTranscript === "transcript1") {
			for (let i = 0; i < this.audios.length; i++) {
				if(this.audios[i].wrapper.id == "audiojs_wrapper2" && this.audios[i].playing) {
					this.audios[i].playPause();
				}
			}
		}

		$("body").css("overflow","");
		TweenMax.to($(".popUpTranscript"), 0.2, {css:{autoAlpha:0}, ease:'Cubic.easeOut'});
		TweenMax.to($(".popUpTranscript .content"), 0.2, {css:{autoAlpha:0}, ease:'Cubic.easeOut', onComplete: () => {
			// Focus on triggered btn
			$(`.triggerTranscript[data-label='${currentTranscript}']`).focus();
			$(".popUpTranscript")[0].removeAttribute("data-current-transcript");
		}});

		// Unlock tabs
		AccessibilityHelper.unlockTab();	

		if($(".popUpTranscript iframe").length){
			$(".popUpTranscript iframe").remove();
			$(".contentTranscript").removeClass("open");
			TweenMax.to($(".ctaShow .show"), 0.2, {css:{autoAlpha:1}, ease:'Cubic.easeOut'});
			TweenMax.to($(".ctaShow .hide"), 0.2, {css:{autoAlpha:0}, ease:'Cubic.easeOut'});

			$(".ctaShow")[0].classList.remove("opened");
			$(".ctaShow")[0].classList.add("closed");
		}
	}

	toggleVideoTranscript(e) {
		if(e.target.classList.contains("closed"))
			this.openVideoTranscript(e);
		else
			this.closeVideoTranscript(e);
	}
	openVideoTranscript(e){
		e.target.classList.remove("closed");
		e.target.classList.add("opened");

		$(e.currentTarget).siblings(".contentTranscript").addClass("open");

		TweenMax.to(e.currentTarget.querySelector(".show"), 0.2, {css:{autoAlpha:0}, ease:'Cubic.easeOut', onComplete:()=>{
	        TweenMax.to(e.currentTarget.querySelector(".hide"), 0.2, {css:{autoAlpha:1}, ease:'Cubic.easeOut'});
	    }});
	}

	closeVideoTranscript(e){
		e.target.classList.remove("opened");
		e.target.classList.add("closed");

		$(e.currentTarget).siblings(".contentTranscript").removeClass("open");
		TweenMax.to(e.currentTarget.querySelector(".hide"), 0.2, {css:{autoAlpha:0}, ease:'Cubic.easeOut', onComplete:()=>{
	        TweenMax.to(e.currentTarget.querySelector(".show"), 0.2, {css:{autoAlpha:1}, ease:'Cubic.easeOut'});
	    }});
	}

	anchorScroll(e){
		e.preventDefault;
		let curScroll = {y:$(window).scrollTop()};
		let nthChild = $(e.currentTarget).parent().index() + 2;
		let position = ($(".blockTxtMoz:nth-child("+nthChild+")").offset().top) - $("header").height();
        TweenMax.to(curScroll, 0.5, {y:position, onUpdate:()=> { window.scrollTo(0, curScroll.y); }, ease:'cubic.easeOut'});
	}

	tiggerOverlay(e){
		$(e.currentTarget).siblings("img").trigger("click");
	}

	/**
	 * SHOW AUDIO PLAYER
	 */
	showPLayer(e){
		e.preventDefault();

		const audioPlayer = $(e.currentTarget).siblings(".audiojs");

		audioPlayer[0].style.display = 'block';

		TweenMax.to($(e.currentTarget).siblings("p"), 0.2, {css:{autoAlpha:0}, ease:'Cubic.easeOut'});
		TweenMax.to($(e.currentTarget), 0.2, {css:{autoAlpha:0}, ease:'Cubic.easeOut', onComplete:()=>{
	        $(e.currentTarget).css("z-index","0");
	    }});
		TweenMax.to(audioPlayer, 0.2, {css:{autoAlpha:1}, ease:'Cubic.easeOut', onComplete:()=>{
			// Play audio
			audioPlayer.find(".play").trigger("click");

			// Toggle Blur / Focus on Pause / Play
			audioPlayer[0].querySelector("audio").addEventListener("play", this.onAudioPlayed);
			audioPlayer[0].querySelector("audio").addEventListener("pause", this.onAudioPaused);
		}});
	}

	onAudioPlayed(event) {
		event.target.parentElement.querySelector(".pause").focus();
	}
	onAudioPaused(event) {
		event.target.parentElement.querySelector(".play").focus();
	}

	addTabText(){
		$("#page-content a[target=_blank]").each((i,e)=>{
			$(e).append("<span class='hidden-sr'>"+insertText.newTabs+"</span>")
		});
	}
	
	mobileMenu(e){
		if($(window).width()<=1100){
			e.preventDefault();
			var siblingBox = $(e.currentTarget).index()+1;
			var _selectBox = $(".mobileMenu"+siblingBox+"");
			this.heightBox = _selectBox.find(">div").height();

			if((_selectBox).hasClass("openSubMobile")){
				_selectBox.css("height","0px");
				_selectBox.removeClass("openSubMobile");
			}else{
				$(".openSubMobile").css("height","0px").removeClass("openSubMobile");
				_selectBox.addClass("openSubMobile");
				_selectBox.height(this.heightBox);
			}

			if($(e.currentTarget).children('a').hasClass('active')){
				$(e.currentTarget).children('a').removeClass("active");
			}else{
				$(e.currentTarget).children('a').addClass("active");
				if($(e.currentTarget).children('a').attr('id') == 'explorebtn'){
					$('#discoverbtn').removeClass("active");
				}else{
					$('#explorebtn').removeClass("active");
				}
			}
		}
	}
}

export default DefaultView;